.WebShell {
    display: flex;
    height: 100%;
    width: 100%;
    .LeftSection {
        height: 100%;
        position: relative;
    }
    .RightSection {
        flex: 1;
    }
}
