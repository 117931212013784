.AlertCustom{
    height: 60px;
    position: fixed !important;
    bottom: 0;
    width: calc(100% - 200px);
    &.ant-alert {
        padding: 8px 50px !important;
    }
    &.ant-alert-info{
        background-color: #6081F3;
        .ant-alert-icon {
            color: #FFFFFF;
            font-size: 20px;
        }
    }
    .ant-alert-content {
        .ant-alert-message {
            color: #FFFFFF;
            font-size: 20px;
        }
    }
    .ant-alert-action {
        button {
            span {
                color: #FFFFFF;
                font-weight: 600;
                line-height: 20px;
                font-size: 14px;
            }
        }
    }
    .ant-alert-close-icon{
        line-height: 20px;
        font-size: 14px;
        .ant-alert-close-text {
            color: #FFFFFF;
            font-weight: 600;
        }
        
    }
}