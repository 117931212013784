@import "./variables.scss";
.ManageCoursePage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Steps {
    position: relative;
    margin: 40px auto;
    left: 10%;
  }
}

.Field {
  margin-bottom: 15px;
}

.Icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

ul li,
ol {
  margin-left: 20px;
}

.Error {
  input {
    border-color: $error-color !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
}

.ErrorMessage,
.Required {
  color: $error-color;
}
.Flex {
  display: flex;
  &.JustifyContentBetween {
    justify-content: space-between;
  }
  &.JustifyContentCenter {
    justify-content: center;
  }
  &.JustifyContentEnd {
    justify-content: flex-end;
  }
}
.ContainerBody {
  padding: 40px 30px;
  .ant-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .ant-row .ant-col {
    padding: 0px 20px;
  }
}
.ContainerContent {
  padding-left: 7.5%;
  padding-right: 7.5%;
  .ant-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .ant-row .ant-col {
    padding: 0px 20px;
  }
}
.LabelIcon {
  font-weight: bolder;
  font-size: 16px;
  color: $light-gray;
  margin-bottom: 10px;
  margin-top: 20px;
  img {
    margin-right: 20px;
  }
}
.ModalConfirm {
  .ant-modal-body {
    padding: 40px;
  }
  .ant-modal-content {
    border-radius: 16px;
    width: 600px;
    .ant-modal-close .ant-modal-close-x {
      width: auto !important;
      margin-right: 20px;
      .ActionClose {
        font-size: 16px;
        cursor: pointer;
        .anticon {
          font-size: 20px;
          margin-left: 10px;
          color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            $error-color;
        }
      }
    }
  }
  .ant-modal-confirm-title {
    font-size: 26px;
  }
  .ant-typography-danger {
    color: $error-color;
  }
  .ant-modal-confirm-content {
    margin-left: 0px !important;
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
      color: #808080;
      padding-top: 20px;
    }
  }
  .ant-modal-confirm-body {
    .anticon {
      font-size: 34px;
    }
  }
  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    .ant-btn {
      width: 90px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      &.ButtonOk {
        background-color: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          $error-color;
      }
      &.ButtonCancel {
        background-color: #f2f2f2;
        color: #333333;
        &:hover {
          border-color: #f2f2f2;
        }
      }
      &.BtnDisplayNone {
        display: none;
      }
    }
  }
}

.TopBar {
  .Menu {
    display: flex;
    .MenuItem {
      margin-right: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #666;
    }
  }
}

.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.ant-table {
  border: 1px solid #e5e5e5;

  .ant-table-thead > tr > th {
    background-color: #fff;
    font-weight: bold;
    .anticon {
      float: right;
      color: rgba($color: $light-primary-color, $alpha: 0.5);
    }
    &::before {
      display: none;
    }
  }

  .ant-table-tbody {
    .ant-table-row-level-0 {
      .ant-table-cell-with-append {
        font-weight: bold;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.95),
            rgba(255, 255, 255, 0.95)
          ),
          $light-primary-color;
      }
    }

    .ant-table-row-level-1 {
      font-weight: normal !important;
      background: #fff !important;
    }

    .ant-table-row:nth-child(odd) {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.95),
          rgba(255, 255, 255, 0.95)
        ),
        $light-primary-color;
    }
  }
}
.ant-pagination-item-link {
  border: none !important;
  background-color: transparent !important;
}
.ant-pagination-item:not(.ant-pagination-item-active) {
  border: none !important;
  background-color: transparent !important;
}
.ant-pagination-item {
  margin: 0px !important;
}
.ant-pagination-next {
  margin-left: 10px !important;
}
.ant-pagination-item-active {
  background: $light-primary-color;
  a,
  &:hover a {
    color: #fff;
  }
}
.ant-pagination-disabled {
  background: #f2f2f2;
  border-radius: 2px;
  color: $light-gray;
}
.confirm-custom {
  .ant-modal-confirm-body .ant-modal-confirm-title {
    text-align: center;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    margin-top: 24px;
    display: flex !important;
    justify-content: center !important;
    float: none !important;
    .btn-lg {
      padding-left: 25px;
      padding-right: 25px;
    }
    .btn-bg-red {
      background: #ff4d4f;
      border: none;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-switch-checked,
.ant-radio-checked .ant-radio-inner:after,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $success-color;
}

.TextSecondary {
  color: $secondary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox,
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-switch-checked,
.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner {
  border-color: $success-color !important;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
  &::after {
    width: 12px;
    height: 12px;
  }
}

.Notification {
  .ant-alert-info {
    background-color: $secondary-color;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 20px 20px;
  }
}

.ant-btn-primary {
  border-color: $secondary-color;
  background-color: $secondary-color;
}

.ant-btn-primary:disabled {
  border-color: #eee !important;
  background-color: #eee !important;
}

.DeleteButton {
  background: $error-color;
}

.TextAlignLeft {
  text-align: left;
}

.TextAlignCenter {
  text-align: center;
}

.TextAlignRight {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-lighter {
  font-weight: lighter;
}
.text-bolder {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.color-blue {
  color: $secondary-color;
}
.color-green {
  color: $success-color;
}
.color-grey {
  color: $gray-color;
}
.color-info {
  color: $light-primary-color;
}
input.LargeInput,
.LargeInput input,
.ant-picker {
  padding: 15px 20px !important;
}

.LargeSelection {
  .ant-select-selector {
    padding: 10px 20px !important;
    height: unset !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(138, 138, 138, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #ccc;
  &:hover {
    background: #c6c6c6;
  }
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(138, 138, 138, 0.1);
}
.HeaderSection {
  justify-items: center;
  display: flex;
  justify-content: space-between;
  h3.SectionTitle {
    display: inline-block;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #333333;
  }
  ul.SectionFilter {
    display: inline-flex;
    list-style: none;
    li {
      padding: 6px 16px;
      background: #f2f2f2;
      border-radius: 20px;
      cursor: pointer;
      color: rgba(51, 66, 82, 0.5);
      &.Active {
        color: #ffffff;
        background: linear-gradient(
          127.54deg,
          #6794f8 9.23%,
          #54b1f6 198.83%,
          #6c96f9 198.85%
        );
      }
    }
  }
}
.CarouselCard {
  width: 280px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  padding: 4px;
  border-radius: 8px;
  background: #fff;
  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .CardAction {
    position: absolute;
    top: 20px;
    right: 20px;
    .anticon {
      background-color: rgba(51, 66, 82, 0.15);
      border-radius: 50%;
      height: 26px;
      width: 26px;
      text-align: center;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .CardContent {
    background-color: #ffffff;
    padding: 12px 20px 20px;
    box-shadow: 0px 2px 6px rgba(169, 187, 253, 0.12),
      0px 20px 16px rgba(0, 0, 0, 0.04), 0px 8px 6px rgba(7, 41, 161, 0.04);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .Title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-lighter {
  font-weight: lighter;
}
.text-bolder {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.color-blue {
  color: $secondary-color;
}
.color-green {
  color: #56c59c;
}
.color-grey {
  color: $gray-color;
}
