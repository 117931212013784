.SideBarPlaceholder {
  width: 220px;
  transition: width 0.3s ease-in;

  &.Minimize {
    width: 80px;
  }
}

.Sidebar {
  height: 100%;
  overflow: auto;
  background: linear-gradient(
      0deg,
      rgba(255, 2555, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #a2cfff;
  box-shadow: 4px 0px 28px rgba(0, 0, 0, 0.02),
    2px 0px 12px rgba(25, 33, 41, 0.04);
  color: #fff;
  padding: 20px 0;
  width: 220px;
  transition: width 0.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;

  .ToggleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 20px 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(121, 121, 121);
    .ToggleButton {
      background: #1890ff;
      color: #fff;
      font-size: 14px;
      padding: 3px 10px;
      cursor: pointer;
      border-radius: 2px;
      i {
        margin-left: 3px;
      }
    }
  }

  .Logo {
    width: 100px;
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
      width: 100%;
    }
  }

  &.Minimize {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    .SidebarItem {
      justify-content: center;
      .MainMenu .link,
      .SubMenu {
        display: none;
      }

      .MainMenu {
        justify-content: center;
      }
    }
  }
  .BottomSidebar {
    border-top: 1px solid rgb(121, 121, 121);
  }
}

.SidebarItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .link {
    background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.35),
        rgba(255, 255, 255, 0.35)
      ),
      -webkit-linear-gradient(0deg, #334252, #334252);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .MainMenu {
    display: flex;
    padding: 10px;
    .link {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-left: 10px;
    }
    .anticon {
      color: #ccc;
    }
  }

  &.Active {
    .MainMenu {
      background: #1890ff;
      .link {
        font-weight: 600;
        background: #fff;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #fff;
      }
    }
  }

  &.Disabled {
    opacity: 0.5;
  }
}

.SubMenu {
  padding-left: 15px;
  margin: 10px 0 0 15px;
  border-left: 1px solid rgb(121, 121, 121);
  .SubMenuItem {
    position: relative;
    padding: 5px 0;
    a {
      background: -webkit-linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.35),
          rgba(255, 255, 255, 0.35)
        ),
        -webkit-linear-gradient(0deg, #334252, #334252);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }

    &.Active {
      a {
        background: unset;
        color: #fff;
        -webkit-text-fill-color: unset;
        font-weight: bold !important;
      }
    }
  }
}
