$primary-color: rgba(0, 0, 0, 0.65);
$secondary-color: #6081f3;
$success-color: #9bce68;
$light-primary-color: #299ef4;
$error-color: #f26a61;
$light-gray: #333;
$light-link-color: #293cff;
$lighter-gray: #4d4d4d;
$gray-color: #666;
$yellow-color: #DBDF02D9;
